.leaflet-control-wms-legend
{
    display: block;
    padding: 3px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.65);
    cursor: auto;
    text-align: center;
    background-color: #FFFFFF;
}

.leaflet-control-wms-legend:hover
{
    background-color: #F4F4F4;
}
