/* レンジデザイン設定 */
input[type="range"] {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    width: 110px;
    height: 10px;
    margin: 0;
    border: none;
    padding: 1px 2px;
    border-radius: 30px;
    background: #F1F0EE;
    outline: none;
}
input[type="range"]::-ms-track {
    border: inherit;
    color: transparent;
    background: transparent;
}
input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
    background: transparent;
}
input[type="range"]::-ms-tooltip {
    display: none;
}
input[type="range"]::-ms-thumb {
    width: 15px;
    height: 18px;
    border-radius: 12px;
    border: 0;
    background-image: linear-gradient(to bottom, #1253A4 0, #1253A4 100%);
}

/* 題名レイアウト */
.leaflet-control-layers-label {
    margin: 0px 0px 8px 1px
}