.leaflet-control-minimap {
	border:solid rgba(255, 255, 255, 1.0) 4px;
	box-shadow: 0 1px 5px rgba(0,0,0,0.65);
	border-radius: 3px;
	background: #f8f8f9;
	transition: all .6s;
}

.leaflet-control-minimap a {
	background-color: rgba(255, 255, 255, 1.0);
	background-repeat: no-repeat;
	z-index: 99999;
	transition: all .6s;
}

.leaflet-control-minimap a.minimized-bottomright {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	border-radius: 0px;
}

.leaflet-control-minimap a.minimized-topleft {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	border-radius: 0px;
}

.leaflet-control-minimap a.minimized-bottomleft {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
	border-radius: 0px;
}

.leaflet-control-minimap a.minimized-topright {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	border-radius: 0px;
}

.leaflet-control-minimap-toggle-display{
	background-image: url("images/toggle.svg");
	background-size: cover;
	position: absolute;
	border-radius: 3px 0px 0px 0px;
}

.leaflet-oldie .leaflet-control-minimap-toggle-display{
	background-image: url("images/toggle.png");
}

.leaflet-control-minimap-toggle-display-bottomright {
	bottom: 0;
	right: 0;
}

.leaflet-control-minimap-toggle-display-topleft{
	top: 0;
	left: 0;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.leaflet-control-minimap-toggle-display-bottomleft{
	bottom: 0;
	left: 0;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.leaflet-control-minimap-toggle-display-topright{
	top: 0;
	right: 0;
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}

/* Old IE */
.leaflet-oldie .leaflet-control-minimap {
	border: 1px solid #999;
}

.leaflet-oldie .leaflet-control-minimap a {
	background-color: #fff;
}

.leaflet-oldie .leaflet-control-minimap a.minimized {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
