/* PANEL LAYERS */
.leaflet-panel-layers .leaflet-panel-layers-list {
  display: block;
}
.leaflet-panel-layers.expanded .leaflet-panel-layers-list {
  display: block;
}
.leaflet-top.leaflet-right .leaflet-panel-layers:not(.compact) {
  margin: 0;
}
.leaflet-panel-layers {
  width: 30px;
  min-width: 30px;
}
.leaflet-panel-layers.expanded {
  width: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.leaflet-panel-layers.expanded .leaflet-panel-layers-list {
  display: block;
}
.leaflet-panel-layers:not(.expanded) .leaflet-panel-layers-title > span,
.leaflet-panel-layers:not(.expanded) .leaflet-panel-layers-selector,
.leaflet-panel-layers:not(.expanded) .leaflet-panel-layers-grouplabel {
  display: none;
}
.leaflet-panel-layers-separator {
  clear: both;
}

.leaflet-panel-layers-item .leaflet-panel-layers-title {
  display: block;
  white-space: nowrap;
  float: none;
  cursor: pointer;
}
.leaflet-panel-layers-title .leaflet-panel-layers-selector {
  float: right;
}

.leaflet-panel-layers-group {
  position: relative;
  width: auto;
  height: auto;
  clear: both;
  overflow: hidden;
}
.leaflet-panel-layers-icon {
  text-align: center;
  float: left;
}
.leaflet-panel-layers-group.collapsible:not(.expanded) {
  height: 20px;
}
.leaflet-panel-layers-group.collapsible:not(.expanded) .leaflet-panel-layers-grouplabel {
  height: 20px;
  overflow: hidden;
}
.leaflet-panel-layers-group.collapsible:not(.expanded) .leaflet-panel-layers-item {
  display: none;
}
.leaflet-panel-layers-group.collapsible .leaflet-panel-layers-grouplabel {
  display: block;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.leaflet-panel-layers-item {
  display: block;
  height: auto;
  clear: both;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.leaflet-panel-layers-overlays .leaflet-panel-layers-item {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  width: auto;
  display: block;
}
.leaflet-panel-layers-base .leaflet-panel-layers-selector {
  float: left;
}
.leaflet-panel-layers-overlays .leaflet-panel-layers-selector {
  float: right;
}
.leaflet-panel-layers.expanded .leaflet-panel-layers-overlays input {
  display: block;
}
.leaflet-control-layers-selector {
  float: left;
}


/* theming style */

.leaflet-panel-layers {
  padding: 4px;
  background: rgba(255,255,255,0.5);
  box-shadow: -2px 0 8px rgba(0,0,0,0.3);
}
.leaflet-panel-layers.expanded {
  padding: 4px;
}
.leaflet-panel-layers-selector {
  position: relative;
  top: 1px;
  margin-top: 2px;
}
.leaflet-panel-layers-separator {
  height: 8px;
  margin: 12px 4px 0 4px;
  border-top:1px solid rgba(0,0,0,0.3);
}
.leaflet-panel-layers-item {
  min-height: 20px;
}
.leaflet-panel-layers-margin {
  height: 25px;
}
.leaflet-panel-layers-icon {
  line-height: 20px;
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #fff;
}
.leaflet-panel-layers-group.collapsible .leaflet-panel-layers-icon:first-child {
  min-width: 20px;
  font-size: 16px;
  text-align: center;
  background: none;
}
.leaflet-panel-layers-group {
  padding: 2px 4px;
  margin-bottom: 4px;
  border: 1px solid rgba(0,0,0,0.3);
  background: rgba(255,255,255,0.6);
  border-radius: 3px;
}
.leaflet-panel-layers-overlays .leaflet-panel-layers-item {
  margin-bottom: 4px;
  padding: 2px;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 4px;
}
.leaflet-panel-layers-overlays .leaflet-panel-layers-item:hover {
  border: 1px solid #888;
  cursor: pointer;
}
