$gutter: 1rem;
.leaflet-container {  width: 100%;  height: 90vh; }
.leaflet-popup-content{ overflow: auto}

.beta-logo{
    font-size:12px
}

#chartdiv {
    width: 100%;
    height: 500px;
}


#landcover {
    width: 100%;
    height: 500px;
    max-height:60vh;
    margin:auto;
}

#landdegradation {
    width: 350px;
    height: 200px;
    max-height:60vh;
    margin:auto;
}

#soilcarbon {
    width: 100%;
    max-width:600px;
    height: 500px;
    max-height:60vh;
    margin:auto;
}

#ndvi {
    width: 100%;
    height: 400px;
    margin:auto;
}

#sdg15 {
    width: 100%;
    max-width:900px;
    height: 500px;
}

#waterfall {
    width: 100%;
    max-width:900px;
    height: 500px;
}

#population {
    width: 100%;
    max-width:900px;
    height: 500px;
    margin:auto;
}


#temperature {
    width: 100%;
    height: 500px;
}

#spectralindices {
    width: 100%;
    height: 500px;
}



#spectralindices2 {
    width: 100%;
    height: 500px;
}


#rcp {
    width: 100%;
    height: 350px;
}


.landmanagementspider{
    width: 100%;
    max-width:600px;
    height: 500px;
    max-height:60vh;
}

.large-logo{
    width:100%!important;
    heihgt:100%!important
}

.cacip-logo{
    padding:10px;
    vertical-align:middle;
    margin-right:.5rem;
    width:100%;
    heihgt:100%
}

/*Legend specific*/
.legend {
    padding: 6px 8px;
    font: 14px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
    /*border-radius: 5px;*/
    line-height: 24px;
    color: #555;
}
.legend h4 {
    text-align: center;
    font-size: 16px;
    margin: 2px 12px 8px;
    color: #777;
}

.legend span {
    position: relative;
    bottom: 3px;
}

.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin: 0 8px 0 0;
    opacity: 0.7;
}

.legend i.icon {
    background-size: 18px;
    background-color: rgba(255, 255, 255, 1);
}


.picklist-demo .product-item {
    display: flex;
    align-items: center;
    padding: .02rem;
    width: 100%;
}

.picklist-demo .product-item img {
    width: 75px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 1rem;
}

.picklist-demo .product-item .product-list-detail {
    flex: 1 1 0;
}

.picklist-demo .product-item .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.picklist-demo .product-item .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.picklist-demo .product-item .product-category {
    vertical-align: middle;
    line-height: 1;
}

@media screen and (max-width: 576px) {
    .picklist-demo .product-item {
        flex-wrap: wrap;
    }

    .picklist-demo .product-item .image-container {
        width: 100%;
        text-align: center;
    }

    .picklist-demo .product-item img {
        margin: 0 0 1rem 0;
        width: 100px;
    }
}

.datatable-editing-demo .editable-cells-table td.p-cell-editing {
    padding-top: 0;
    padding-bottom: 0;
}

@keyframes pulse {
    0% {
        background-color: rgba(165, 165, 165, 0.1)
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3)
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1)
    }
}

.customer-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.customer-badge.status-qualified {
    background-color: #C8E6C9;
    color: #256029;
}

.customer-badge.status-unqualified {
    background-color: #FFCDD2;
    color: #C63737;
}

.customer-badge.status-negotiation {
    background-color: #FEEDAF;
    color: #8A5340;
}

.customer-badge.status-new {
    background-color: #B3E5FC;
    color: #23547B;
}

.customer-badge.status-renewal {
    background-color: #ECCFFF;
    color: #694382;
}

.customer-badge.status-proposal {
    background-color: #FFD8B2;
    color: #805B36;
}

.product-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.product-badge.status-instock {
    background: #C8E6C9;
    color: #256029;
}

.product-badge.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
}

.product-badge.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
}

.order-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.order-badge.order-delivered {
    background: #C8E6C9;
    color: #256029;
}

.order-badge.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
}

.order-badge.order-pending {
    background: #FEEDAF;
    color: #8A5340;
}

.order-badge.order-returned {
    background: #ECCFFF;
    color: #694382;
}

.image-text {
    vertical-align: middle;
    margin-left: .5rem;
}

.p-multiselect-representative-option {
    display: inline-block;
    vertical-align: middle;
}

.p-multiselect-representative-option img {
    vertical-align: middle;
    width: 24px;
}

.p-multiselect-representative-option span {
    margin-top: .125rem;
}

.p-column-filter {
    width: 100%;
}

.country-item {
    display: flex;
    align-items: center;
}

.country-item img.flag {
    width: 18px;
    margin-right: .5rem;
}

.flag {
    vertical-align: middle;
}

span.flag {
    width:44px;
    height:30px;
    display:inline-block;
}

img.flag {
    width:30px
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.admin-level-0 {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI2MHB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA2MCA2MCIgd2lkdGg9IjYwcHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjx0aXRsZS8+PGRlc2MvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJQZW9wbGUiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iSWNvbi04MCI+PHBhdGggZD0iTTM2LDI0IEwzNiwzNiBDMzYsMzkuMzA5IDMzLjMwOSw0MiAzMCw0MiBDMjguMzksNDIgMjYuODc2LDQxLjM3IDI1LjczOSw0MC4yMjYgQzI1LjM1LDM5LjgzNCAyNS4zNTIsMzkuMiAyNS43NDMsMzguODEyIEMyNi4xMzUsMzguNDIxIDI2Ljc2OCwzOC40MjQgMjcuMTU3LDM4LjgxNSBDMjcuOTE2LDM5LjU3OSAyOC45MjYsNDAgMzAsNDAgQzMyLjIwNiw0MCAzNCwzOC4yMDYgMzQsMzYgTDM0LDI0IEMzNCwyMS43OTQgMzIuMjA2LDIwIDMwLDIwIEMyNy43OTQsMjAgMjYsMjEuNzk0IDI2LDI0IEwyNiwzNiBDMjYsMzYuNTUzIDI1LjU1MywzNyAyNSwzNyBDMjQuNDQ3LDM3IDI0LDM2LjU1MyAyNCwzNiBMMjQsMjQgQzI0LDIwLjY5MSAyNi42OTEsMTggMzAsMTggQzMzLjMwOSwxOCAzNiwyMC42OTEgMzYsMjQgTTMwLDU4IEMxNC41NjEsNTggMiw0NS40MzkgMiwzMCBDMiwxNC41NjEgMTQuNTYxLDIgMzAsMiBDNDUuNDM5LDIgNTgsMTQuNTYxIDU4LDMwIEM1OCw0NS40MzkgNDUuNDM5LDU4IDMwLDU4IE0zMCwwIEMxMy40NTgsMCAwLDEzLjQ1OCAwLDMwIEMwLDQ2LjU0MiAxMy40NTgsNjAgMzAsNjAgQzQ2LjU0Miw2MCA2MCw0Ni41NDIgNjAsMzAgQzYwLDEzLjQ1OCA0Ni41NDIsMCAzMCwwIiBpZD0ibnVtYmVyLXRlbiIvPjwvZz48L2c+PC9zdmc+);
}

.admin-level-1 {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI2MHB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA2MCA2MCIgd2lkdGg9IjYwcHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjx0aXRsZS8+PGRlc2MvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJQZW9wbGUiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iSWNvbi03MSI+PHBhdGggZD0iTTMyLDE5IEwzMiw0MSBDMzIsNDEuNTUzIDMxLjU1Miw0MiAzMSw0MiBDMzAuNDQ4LDQyIDMwLDQxLjU1MyAzMCw0MSBMMzAsMjEuNDE0IEwyNS43MDcsMjUuNzA3IEMyNS4zMTYsMjYuMDk4IDI0LjY4NCwyNi4wOTggMjQuMjkzLDI1LjcwNyBDMjMuOTAyLDI1LjMxNiAyMy45MDIsMjQuNjg0IDI0LjI5MywyNC4yOTMgTDMwLjI5MywxOC4yOTMgQzMwLjU3OSwxOC4wMDYgMzEuMDA4LDE3LjkxOSAzMS4zODMsMTguMDc2IEMzMS43NTYsMTguMjMgMzIsMTguNTk2IDMyLDE5IE0zMCw1OCBDMTQuNTYxLDU4IDIsNDUuNDM5IDIsMzAgQzIsMTQuNTYxIDE0LjU2MSwyIDMwLDIgQzQ1LjQzOSwyIDU4LDE0LjU2MSA1OCwzMCBDNTgsNDUuNDM5IDQ1LjQzOSw1OCAzMCw1OCBNMzAsMCBDMTMuNDU4LDAgMCwxMy40NTggMCwzMCBDMCw0Ni41NDIgMTMuNDU4LDYwIDMwLDYwIEM0Ni41NDIsNjAgNjAsNDYuNTQyIDYwLDMwIEM2MCwxMy40NTggNDYuNTQyLDAgMzAsMCIgaWQ9Im51bWJlci1vbmUiLz48L2c+PC9nPjwvc3ZnPg==);
}

.admin-level-2 {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI2MHB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA2MCA2MCIgd2lkdGg9IjYwcHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjx0aXRsZS8+PGRlc2MvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJQZW9wbGUiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iSWNvbi03MiI+PHBhdGggZD0iTTI2LjcwNywzOC43MDcgQzI2LjY5MywzOC43MjIgMjYuMzA0LDM5LjE0OCAyNi4xMDksNDAgTDM1LDQwIEMzNS41NTIsNDAgMzYsNDAuNDQ3IDM2LDQxIEMzNiw0MS41NTMgMzUuNTUyLDQyIDM1LDQyIEwyNSw0MiBDMjQuNDQ4LDQyIDI0LDQxLjU1MyAyNCw0MSBDMjQsMzguODUgMjQuOTc1LDM3LjYzOSAyNS4yMzksMzcuMzQ5IEwzMi4yMSwyOC4zODYgQzMyLjIzNiwyOC4zNTMgMzIuMjY0LDI4LjMyMiAzMi4yOTMsMjguMjkzIEMzMi4zMDcsMjguMjc4IDM0LDI2LjUyNiAzNCwyNCBDMzQsMjEuNzk0IDMyLjIwNiwyMCAzMCwyMCBDMjcuNzk0LDIwIDI2LDIxLjc5NCAyNiwyNCBDMjYsMjQuNTUzIDI1LjU1MiwyNSAyNSwyNSBDMjQuNDQ4LDI1IDI0LDI0LjU1MyAyNCwyNCBDMjQsMjAuNjkxIDI2LjY5MSwxOCAzMCwxOCBDMzMuMzA5LDE4IDM2LDIwLjY5MSAzNiwyNCBDMzYsMjcuMTM2IDM0LjEwMiwyOS4yOSAzMy43NTcsMjkuNjU1IEwyNi43OSwzOC42MTQgQzI2Ljc2NCwzOC42NDYgMjYuNzM2LDM4LjY3OCAyNi43MDcsMzguNzA3IE0zMCw1OCBDMTQuNTYxLDU4IDIsNDUuNDM5IDIsMzAgQzIsMTQuNTYxIDE0LjU2MSwyIDMwLDIgQzQ1LjQzOSwyIDU4LDE0LjU2MSA1OCwzMCBDNTgsNDUuNDM5IDQ1LjQzOSw1OCAzMCw1OCBNMzAsMCBDMTMuNDU4LDAgMCwxMy40NTggMCwzMCBDMCw0Ni41NDIgMTMuNDU4LDYwIDMwLDYwIEM0Ni41NDIsNjAgNjAsNDYuNTQyIDYwLDMwIEM2MCwxMy40NTggNDYuNTQyLDAgMzAsMCIgaWQ9Im51bWJlci10d28iLz48L2c+PC9nPjwvc3ZnPg==);
}

.admin-level-3 {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI2MHB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA2MCA2MCIgd2lkdGg9IjYwcHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjx0aXRsZS8+PGRlc2MvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJQZW9wbGUiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iSWNvbi03MyI+PHBhdGggZD0iTTM2LDM2IEMzNiwzOS4zMDkgMzMuMzA5LDQyIDMwLDQyIEMyNi42OTEsNDIgMjQsMzkuMzA5IDI0LDM2IEMyNCwzNS40NDcgMjQuNDQ4LDM1IDI1LDM1IEMyNS41NTIsMzUgMjYsMzUuNDQ3IDI2LDM2IEMyNiwzOC4yMDYgMjcuNzk0LDQwIDMwLDQwIEMzMi4yMDYsNDAgMzQsMzguMjA2IDM0LDM2IEMzNCwzMy43OTQgMzIuMjA2LDMyIDMwLDMyIEMyOS42NjYsMzIgMjkuMzU0LDMxLjgzMyAyOS4xNjgsMzEuNTU1IEMyOC45ODIsMzEuMjc2IDI4Ljk0OCwzMC45MjQgMjkuMDc3LDMwLjYxNSBMMzMuNSwyMCBMMjUsMjAgQzI0LjQ0OCwyMCAyNCwxOS41NTMgMjQsMTkgQzI0LDE4LjQ0NyAyNC40NDgsMTggMjUsMTggTDM1LDE4IEMzNS4zMzQsMTggMzUuNjQ2LDE4LjE2NyAzNS44MzIsMTguNDQ1IEMzNi4wMTgsMTguNzI0IDM2LjA1MiwxOS4wNzYgMzUuOTIzLDE5LjM4NSBMMzEuNDI5LDMwLjE3MiBDMzQuMDUsMzAuODE0IDM2LDMzLjE4NCAzNiwzNiBNMzAsNTggQzE0LjU2MSw1OCAyLDQ1LjQzOSAyLDMwIEMyLDE0LjU2MSAxNC41NjEsMiAzMCwyIEM0NS40MzksMiA1OCwxNC41NjEgNTgsMzAgQzU4LDQ1LjQzOSA0NS40MzksNTggMzAsNTggTTMwLDAgQzEzLjQ1OCwwIDAsMTMuNDU4IDAsMzAgQzAsNDYuNTQyIDEzLjQ1OCw2MCAzMCw2MCBDNDYuNTQyLDYwIDYwLDQ2LjU0MiA2MCwzMCBDNjAsMTMuNDU4IDQ2LjU0MiwwIDMwLDAiIGlkPSJudW1iZXItdGhyZWUiLz48L2c+PC9nPjwvc3ZnPg==);
}


.g-save.p-togglebutton.p-button.p-highlight {
    background: #689F38;
    border-color: #689F38;
    color: #ffffff;
}

.g-save.p-togglebutton.p-button.p-highlight:hover {
    background: #689F38;
    border-color: #689F38;
    color: #ffffff;
}

.low{
    min-height: 22px;
    background-color: #d43333;
    color: #ffffff;
}

.medium{
    min-height: 22px;
    background-color: #fcdd90;
}

.high{
    min-height: 22px;
    color: #ffffff;
    background-color: #398e3b;
}
.vertical-timeline.vertical-timeline-custom-line::before {
    background: #999999;
}

.layout-footer{
    position:fixed;
    bottom:0;
    right:0!important;
    width:calc(100% - 225px)!important;
}

.layout-content{
    margin-bottom: 60px;
}

.dropdown-demo .p-dropdown {
    width: 14rem;
}

.dropdown-demo .country-item-value img.flag {
    width: 17px;
}

.multiselect-demo1 .p-multiselect {
    min-width: 15rem;
    width: 100%
}

.multiselect-demo1 .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.multiselect-demo1 .multiselect-custom .country-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
}

.multiselect-demo1 .multiselect-custom .country-item-value img.flag {
    width: 18px;
}

#bbox_selector{
    width:70vw;
    height: 30vh;
}

.simple-loading-spinner .p-dialog {
    box-shadow: none ;
}

.simple-loading-spinner .p-dialog-content{
    background-color: transparent;
}

.simple-loading-spinner .p-progress-spinner {
    position: relative;
}

.wms-legend{
    max-height:60vh;
    max-width:10vw
}

.leaflet-control-styleeditor-cancel{
    right: 100%;
}

.p-menu {
    &.p-menu-overlay {
        top: 35px!important;
    }
}




@import 'node_modules/primeflex/primeflex.scss';

@import 'prismjs/themes/prism-coy.css';
@import '@fullcalendar/core/main.css';
@import '@fullcalendar/daygrid/main.css';
@import '@fullcalendar/timegrid/main.css';
@import "@fortawesome/fontawesome-pro/css/all.css";
@import 'leaflet/dist/leaflet.css';
@import './AppDemo.scss';


