.leaflet-control-layers-expanded.leaflet-control-layers-minimap {
    width: 240px;
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

    padding: 0;
    border: 4px solid rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    overflow-x: hidden;
}
.leaflet-minimap-container {
    margin-bottom: 2px;
    position: relative;
    display: block;
    height: 110px;
}
.leaflet-minimap-container .leaflet-minimap-label {
    position: absolute;
    display: block;

    height: 22px;
    bottom: 0;
    left: 0;
    padding: 2px 6px 2px 2px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    white-space: nowrap;
    z-index: 1000;
}
.leaflet-minimap-container .leaflet-minimap-label .leaflet-control-layers-selector {
    top: 3px;
}
.leaflet-minimap {
    width: 100%;
    height: 100%;
}
.leaflet-minimap-container.leaflet-minimap-hidden {
    display: none;
}
.leaflet-control-layers-minimap .leaflet-control-layers-scrollbar{
    overflow-y: unset;
    overflow-x: unset;
}
